import { useAppRouter } from "@/src/useAppRouter"
import classNames from "classnames"
import Link from "next/link"


export default function AccountLocked () {

  const router = useAppRouter()

  if (router.query.popover) return null

  return <>
    <div className="modal-overlay"></div>

    <div
      className={classNames("modal-wrapper")}
      aria-modal={true}
      aria-hidden={true}
      tabIndex={-1}
      role="dialog"
    >
      <div className="modal">
        <div className="modal-body">

          <div className="content padded" style={{maxWidth: 320}}>
            <h2>Account Canceled</h2>
            <p>
              You can reactivate your Social Jazz account from the <b>Billing</b> screen.
            </p>
            <p>
              If you have any questions, please don't hesitate to contact support.
            </p>

            <div className="text-center flex-row gap-2">
              <Link href={router.path('subscription')}><a className="button is-primary">Go to Billing</a></Link>
              <button className='button' onClick={() => sendMessage('help', {context: {status: 'account locked'}})}>
                Contact Support
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>


    <style jsx>{`
      .modal-overlay {
        background-color: #999999;
        left: 0;
        opacity: 0.5;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        opacity: 0.2;
        top: 52px;
      }
      .modal-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: 0;
        position: absolute;
        top: 52px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        align-items: flex-start;
      }

      .modal {
        background: white;
        border-radius: 0.25rem;
        display: block;
        max-height: 100vh;
        margin: 4rem 0;
        overflow-y: auto;
        position: relative;
        z-index: 3;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
      }

      .modal-body {
      }

    `}</style>
  </>

}