/* eslint @next/next/no-img-element: 0 */  // --> OFF

import { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import Modal from './Modal'
import { ordinalize, titleize } from '@/lib/string'
import SimpleDate from '@/lib/SimpleDate'
import { sendMessage } from '@/src/useMessage'
import { refreshApiData } from '@/src/useApi'
import rpc from '@/src/rpc'
import { $ } from "@/lib/string"
import useEnv from '@/src/useEnv'
import useRpc from '@/src/useRpc'
import useHighlight from '@/src/useHighlight'
import LoadingSpinner from './LoadingSpinner'
import { jazzlinkMode, openPopup } from '@/src/misc'
import { refreshCurrentOrg } from '@/src/useCurrentOrg'
import Table from './Table'
import Link from 'next/link'
import Tag from './Tag'
import CloseIcon from './icons/CloseIcon'
import useDeviceInfo from '@/src/useDeviceInfo'
import withLoading from '@/src/withLoading'
import PlanSelector from './PlanSelector'


export default function PaymentSettingsLoader ({org, user}) {

  const [billing, _billingError, reloadBilling] = useRpc('billing.info')

  async function reload () {
    await withLoading(async () => {
      await refreshCurrentOrg()
      await reloadBilling()
    })
  }

  if (!billing) return <LoadingSpinner size="medium" padded/>

  return <PaymentSettings org={org} user={user} billing={billing} reload={reload}/>
}



function PaymentSettings ({org, user, billing, reload}) {

  const {width} = useDeviceInfo()

  const addCardRef = useRef(null)
  useHighlight('add-card', addCardRef)

  const updateCardRef = useRef(null)
  useHighlight('update-card', updateCardRef)

  const billingAddressRef = useRef(null)
  useHighlight('billing-address', billingAddressRef)

  const [showSubscriptionUI, setShowSubscriptionUI] = useState(false)
  const [invoicesVisible, setInvoicesVisible] = useState(false)
  const [invoices, _, reloadInvoiceHistory] = useRpc('invoices.recentlyPaid')

  const partnerId = org?.partnerId
  const partner = billing?.partner

  const billingByPartner = partner?.collectsPayment

  const env = useEnv()

  const subscriptionData = { uid: org.uid, userId: user?.id }
  const queryString = new URLSearchParams(subscriptionData).toString()
  const addPaymentUrl = env.PAY_URL ? `${env.PAY_URL}/subscribe?${queryString}`: ''

  const handleMessageFromPopup = useCallback((e) => {
    if (e.origin !== window.location.origin) return
    if (e.data === 'reloadPaymentInfo') refreshCurrentOrg()
  }, [])

  useEffect(() => {
    window.addEventListener('message', handleMessageFromPopup)
    return () => window.removeEventListener('message', handleMessageFromPopup)
  }, [handleMessageFromPopup])


  const inFreeTrial = org.status === 'trialing'

  const today = new SimpleDate()
  const trialEndDate = billing && new SimpleDate(billing.trialEndDate)
  const daysLeftInTrial = today.daysBetween(trialEndDate)

  const hasPaymentMethod = !!billing.creditCard
  const billingDate = billing.nextBillingDate && new SimpleDate(billing.nextBillingDate)
  const activeSubscription = billing?.lastSubscription || billing?.currentSubscription

  const billingCycle = activeSubscription?.kind === 'yearly' ? 'yr' : 'mo'
  const discounted = billing?.nextBillingAmount < activeSubscription?.priceInPennies

  const lastPaymentFailed = billing.lastInvoice?.status === 'failed'
  const needsCreditCard = ((!hasPaymentMethod || lastPaymentFailed) && !billingByPartner && !billing?.freeForever)

  const canUpdatePaymentMethod = !!addPaymentUrl && org.status !== 'lead'
  const showAddCreditCardButton    = canUpdatePaymentMethod && needsCreditCard
  const showUpdateCreditCardButton = canUpdatePaymentMethod && hasPaymentMethod

  const canReactivate = !lastPaymentFailed && (hasPaymentMethod || billingByPartner || billing.nextBillingAmount === 0)

  const cancelDate = billing?.cancelationDate ? new SimpleDate(billing.cancelationDate) : null

  if (partnerId && !partner) return <LoadingSpinner size="medium" padded/>
  const hideBilling = !org.showBilling


  function addPaymentMethod (e) {
    e.preventDefault()

    // Don't allow adding payment method for orgs in dev/staging environments
    if (org.uid === 'REDACTED') {
      alert('Cannot add payment method for org with redacted uid')
      return
    }

    sendMessage('loading', true)

    const popup = jazzlinkMode()
    try {
      const url = addPaymentUrl
      if (popup) {
        openPopup(url)
        return {popup: true}
      } else {
        window.location.href = url
      }
    } catch (err) {
      // TODO: Show some kind of error message in the UI
      console.error(err)
    } finally {
      // In case the redirect fails
      setTimeout(() => { sendMessage('loading', false) }, 3000)
      return {popup}
    }
  }


  async function handlePlanChanged (changed) {
    if (changed) {
      await reload()
      await reloadInvoiceHistory()
    }
    setShowSubscriptionUI(false)
  }


  return <>
    <section>

      <div className='py-2' ref={billingAddressRef} style={{margin: '-0.25rem 0'}}>
        { org.name || org.street || org.city || org.state || org.zip ?
          <>
            <div className='flex-col'>
              <div className='flex-row spread flex-align-start'>
                <h4>
                  Your Billing Address
                </h4>
                <a onClick={() => sendMessage('editOrg')} className="subtle-link text-sm">
                  Edit
                </a>
              </div>

              <div className='hover_link pointer relative py-1' onClick={() => sendMessage('editOrg')}>
                <div>
                  <div>{org.name}</div>
                  <div>{org.street}</div>
                  <div>{org.city} {org.state} {org.zip}</div>
                </div>
              </div>
            </div>
            { !org.zip &&
            <button className="button is-primary full-width mt-2" onClick={() => sendMessage('editOrg')}>
              Finish Billing Address
            </button>
          }
          </>
        :
          <button className="button is-primary full-width" onClick={() => sendMessage('editOrg')}>
            Add Billing Address
          </button>
        }
      </div>

      <hr/>

      { !hideBilling &&
        <>
          <div className='py-2'>
            <div className='flex-row spread flex-align-start'>
              <h4>Payment Method</h4>
              { showUpdateCreditCardButton &&
                <div ref={updateCardRef}>
                  <a className="subtle-link text-sm" onClick={addPaymentMethod}>
                    Update
                  </a>
                </div>
              }
            </div>

            { hasPaymentMethod ?
              <>
                <p>
                  {titleize(billing.creditCard.brand)} •••• •••• •••• {billing.creditCard.lastFour}
                </p>
              </>
            :
              <div>
                { billingByPartner ?
                  <div className='content'>
                    <p>
                      Billing is handled by <span>{partner.logoUrl ? '' : partner.name}</span>
                    </p>
                    { !!partner.logoUrl &&
                      <img src={partner.logoUrl} alt="partner logo" style={{maxWidth: 200}}/>
                    }
                  </div>
                : billing?.freeForever ?
                  <p>
                    No credit card needed.
                  </p>
                : inFreeTrial ?
                  <p className='text-sm'>
                    Add a credit card, to keep your account active{daysLeftInTrial > 0 && <span>. You won't be billed until your free trial ends</span>}.
                  </p>
                : org.status.startsWith('cancel') ?
                  <p>
                    Before you can reactivate your Social Jazz account, you'll need to add a credit card.
                  </p>
                :
                  <p className='text-sm'>
                    Add a credit card, to keep your account active.
                  </p>
                }
              </div>
            }

            { lastPaymentFailed &&
              <p className="red text-sm p-2 bg-red border-red">
              😬 Your last payment failed. Please add a new payment method ASAP.
              </p>
            }

            { showAddCreditCardButton &&
              <div className='mt-3'>
                <button ref={addCardRef} className="button is-primary full-width" onClick={addPaymentMethod}>
                  Add {hasPaymentMethod && 'a new'} Payment Method
                </button>
              </div>
            }

          </div>

          <hr/>
        </>
      }



      { !hideBilling &&
        <>
          <div className='py-2'>
            <div className='flex-row spread flex-align-start'>
              <h4>
                Subscription
              </h4>
              { (org.status === 'active' || inFreeTrial) &&
                <a onClick={() => setShowSubscriptionUI(true)} className="subtle-link text-sm">
                  Change
                </a>
              }
            </div>

            { org.status === 'active' || inFreeTrial ?
              <>
                { activeSubscription ?
                  <div>
                    <div className='flex-row spread'>
                      <div className=''>
                        {activeSubscription.name}
                      </div>
                      <div className="flex-row">
                        <span>{$(activeSubscription.priceInPennies)}</span>
                        <span className='gray text-sm'>/{billingCycle} </span>
                      </div>
                    </div>
                  </div>
                :
                  <p>
                    Your Social Jazz subscription is active.
                  </p>
                }

                { inFreeTrial &&
                  <>
                    <p className={classNames('text-center mt-2 p-1 bg-f3f3f3', {red: (needsCreditCard && daysLeftInTrial <= 3)})}>
                      { daysLeftInTrial === 0 ?
                        <>Your free trial ends today.</>
                      : daysLeftInTrial === 1 ?
                        <>Your free trial ends tomorrow.</>
                      : daysLeftInTrial < 0 ?
                        <>Your free trial has ended.</>
                      :
                        <>{daysLeftInTrial} days left in your free trial.</>
                      }
                    </p>
                  </>
                }

                { needsCreditCard &&
                  <p className="warning text-sm text-center mt-2">
                    Add a payment method, to avoid cancelation.
                  </p>
                }
              </>

            : org.status.startsWith('cancel') ?
              <>
                { org.status === 'canceling' && cancelDate ?
                  <p className='text-sm rounded red bg-red border-red py-2 px-3 mb-2'>
                    Your subscription will end {humanizeDate(cancelDate)}.
                  </p>
                :
                  <p className='red'>
                    { cancelDate ?
                      <>
                        Canceled on <span className='nowrap'>{cancelDate.toEnglish()}</span>.
                      </>
                    :
                      <>
                        Your free trial has ended.
                      </>
                    }
                  </p>
                }

                { canReactivate &&
                  <div className='flex-col flex-align-stretch'>
                    <button className="button is-primary full-width" onClick={() => setShowSubscriptionUI(true)}>
                      Pick a Plan to Reactivate
                    </button>
                  </div>
                }
              </>

            : org.status === 'lead' ?
              <>
                {/* Nothing to show here yet */}
              </>
            :
              <>
                <p>
                  <span className='red block bold'>Your subscription is having issues 🤔</span>
                  Please <a onClick={() => sendMessage('help', {context: {status: 'subscription limbo'}})} className='link'>
                    contact us
                  </a>.
                </p>
              </>
            }
          </div>

          <hr/>

          <div className='flex-row spread flex-align-start'>
            <h4>Next Payment</h4>
            { !billingByPartner && invoices && invoices.length > 0 &&
              <a onClick={() => setInvoicesVisible(true)} className="subtle-link text-sm">
                History
              </a>
            }
          </div>
          { !!billingDate && typeof billing.nextBillingAmount === 'number' && !org.status.startsWith('cancel') ?
            <>
              <div className=''>
                <div className='flex-row spread'>
                  <div>
                    { billingDate ? billingDate.toEnglish() : '' }
                  </div>
                  <div>
                    <span>{$(billing.nextBillingAmount)}</span>
                  </div>
                </div>
                { billingByPartner &&
                  <div className='text-sm mt-2 p-1 bg-f3f3f3'>
                    Your payment will be included in your next bill from {partner.name}.
                  </div>
                }
              </div>
              { discounted &&
                <div className='flex-row flex-center mt-2 p-2 bg-light-green border-green' style={{marginLeft: -2, marginRight: -2}}>
                  <span className='text-sm'>
                    <span className='green'>Your next payment has been discounted &nbsp;</span> 🎉
                  </span>
                </div>
              }
            </>
          : billing?.lastInvoice?.status === 'failed' ?
            <div>
              <div className='flex-row spread'>
                <div className='flex-row flex-align-center gap-1'>
                  {billing.lastInvoice.dueDate ? new SimpleDate(billing.lastInvoice.dueDate).toEnglish() : ''}
                  {billing.lastInvoice.dueDate && billing.lastInvoice.dueDate < today ? <Tag type="red" label="Overdue"/> : ''}
                </div>
                <div>
                  <span>{$(billing.lastInvoice.totalInPennies)}</span>
                </div>
              </div>
            </div>
          :
            <p className='missing'>
              Unknown
            </p>
          }
          <hr/>
        </>
      }


      { (env.IS_DEVELOPMENT && !hideBilling) && <DevCardTools afterAdd={reload} onRemove={hasPaymentMethod ? handleRemoveCard : null}/> }

      <Modal visible={showSubscriptionUI}>
        <div className='content p-4' style={{minWidth: 300}}>
          <PlanSelector org={org} onClose={handlePlanChanged} />
        </div>
      </Modal>


      <Modal visible={invoicesVisible} align="top" onClickOutside={() => setInvoicesVisible(false)}>
        <div className='content p-4' style={{minWidth: 300}}>
          <div className='flex-row flex-center spread pb-2 border-bottom'>
            <h2 className='m-1'>Recent Payments</h2>
            <button onClick={() => setInvoicesVisible(false)} className="button is-inverted no-border px-2">
              <CloseIcon/>
            </button>
          </div>

          <div className='sm_flex'>
            <Table
              data={invoices}
              reverse
              disableSorting
              compact={width && width <= 340}
              preventMobileLayout
              hideRowNumbers
              defaultSortKey="dueDate"
              headings={[
                {key: 'id',      label: 'ID'},
                {key: 'dueDate', label: 'Date'},
                {key: 'total'},
                {key: 'status'},
              ]}
              values={invoice => {
                return {
                  id: invoice.id,
                  dueDate: invoice.dueDate,
                  total: invoice.totalInPennies,
                  status: invoice.status,
                }
              }}
              row={invoice => {
                let dueDate = invoice.dueDate ? new SimpleDate(invoice.dueDate) : ''
                const dueDateValue = dueDate && `${dueDate.monthName.substring(0, 3)} ${dueDate.dayOfMonth} ${dueDate.year}`

                return {
                  id: <Link href={`/invoices/${invoice.id}`}><a className='link' target="_blank">{invoice.id}</a></Link>,
                  dueDate: <span className='nowrap'>{dueDateValue}</span>,
                  total: $(invoice.totalInPennies),
                  status: <Tag type={invoice.status}/>,
                }
              }}
            />
          </div>
        </div>
      </Modal>

    </section>

    <style jsx>{`
      hr {
        margin: 1rem 0;
      }
      section {
        margin: 1rem;
        padding-bottom: 5rem;
      }
      .help {
        margin-top: 1rem;
      }
      .warning {
        color: darkred;
      }
      .warning-modal {
        padding: 1rem;
      }
    `}</style>
  </>


  async function handleRemoveCard (e) {
    e.preventDefault()
    // This is only available in dev environments
    await rpc('billing.removeCreditCard')
    await reload()
    sendMessage('refreshCalendar')
  }
}


function humanizeDate(date) {
  let humanized = ''
  const today = new SimpleDate()

  if (date) {
    if (date.equals(today)) {
      humanized = 'today'
    } else if (date < today) {
      humanized = 'today'
    } else {
      humanized = (
        `${date.monthName.substring(0, 3)} ${ordinalize(date.dayOfMonth)}, ${date.year}`
      )
    }
  }
  return humanized
}


function DevCardTools ({onRemove, afterAdd}) {

  async function handleClick () {
    await rpc('payments.createFakeCard')
    await afterAdd()
    await refreshApiData()
    sendMessage('refreshCalendar')
  }

  return <>
    <div style={{border: '1px dashed red', marginTop: '4rem', padding: '1rem', background: '#ffa'}}>
      <p>
        <b>Dev/Testing Tools</b>
      </p>
      <a className='button' onClick={ handleClick }>
        Add a Card
      </a> &nbsp;
      { !!onRemove &&
        <button className='button is-danger' onClick={onRemove}>
          Remove Card
        </button>
      }
    </div>
  </>
}
