/* eslint @next/next/no-img-element: 0 */
// TODO: Remove the above eslint tweak, after switching to nextjs Image tags
//    import image from "next/image"


import classNames from "classnames"
import { useEffect, useState } from "react"
import ArrowIcon from "./icons/legacy/ArrowIcon"
import DeleteIcon from "./icons/legacy/DeleteIcon"
import LoadingSpinner from "./LoadingSpinner"


export default function PostImagePicker ({imageId, images, width, height, onChange, onRemove, editMode, required}) {

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setMounted(true), 1)
    return () => {clearTimeout(timer)}
  }, [])

  if(!images || !height || !width) return null

  const imageCount = images.length
  const frameCount = imageCount + (required ? 0 : 1)
  const maxIndex = frameCount - 1

  const scaleCurrentImg = 0.7
  const scaleOtherImg = 0.5
  const gap = 6

  const topMargin = 32
  const bottomMargin = 24

  let index = images.findIndex(img => img.id === imageId)
  if (index < 0 || !imageId) index = maxIndex

  const collapsed = !imageId && !editMode

  const classes = classNames(
    'image-picker',
    {
      collapsed,
      'edit-mode': editMode,
      'no-animation': !mounted,
    }
  )

  let pickerHeight = height

  const currentImage = images[index]
  if (currentImage) {
    const scaling = currentImage.width / width
    pickerHeight = currentImage.height / scaling
    if (editMode) {
      pickerHeight = pickerHeight * scaleCurrentImg + topMargin + bottomMargin
    }
  }

  const showDelete = false // currentImage && currentImage.type === 'customer'
  const showPrevButton = frameCount > 1
  const showNextButton = frameCount > 1

  let left = index * width * -1
  if (editMode) {
    left = left * scaleCurrentImg + (width / 2 * (1 - scaleCurrentImg))
  }

  const styles = {
    width: width * (imageCount + 1),
    height: pickerHeight,
  }

  const imageWidth = width

  const frames = Array(frameCount).fill(1)

  function imageStyle (img, i) {
    const currentIndex = imageCount > 0 ? index : 0

    // Check if this image is the one currently selected in the picker
    const isCurrent = i === currentIndex

    const style = {
      top: 0,
      left: imageWidth * (i - currentIndex),
      width,
      height: img ? width / img.width * img.height : pickerHeight,
      transform: 'none',
      transformOrigin: 'top center'
    }

    if (!isCurrent) {
      style.cursor = 'pointer'
    }

    let scale = 1
    if (isCurrent) {
      scale = editMode ? scaleCurrentImg : 1
    } else {
      scale = scaleOtherImg
    }
    style.transform = `scale(${scale})`

    if (editMode) {
      const otherScaledWidth = imageWidth * scaleOtherImg
      const currentScaledWidth = imageWidth * scaleCurrentImg
      let left = (otherScaledWidth + gap) * (i - currentIndex)
      if (i < currentIndex) left -= (currentScaledWidth - otherScaledWidth) / 2
      if (i > currentIndex) left += (currentScaledWidth - otherScaledWidth) / 2
      style.left = left

      style.opacity = (isCurrent ? 1 : 0.4)
    }

    return style
  }

  function imageClickAction(i) {
    if (i === index - 1) return onPrev
    if (i === index + 1) return onNext
  }


  function onPrev() {
    changeIndexBy(-1)
  }

  function onNext() {
    changeIndexBy(+1)
  }

  function changeIndexBy(adjustment) {
    if (images.length === 0) {
      onChange(null)
    } else {
      const oldIndex = images.findIndex(img => img.id === imageId)
      const minIndex = 0
      const maxIndex = images.length + (required ? 0 : 1)
      let newIndex = oldIndex + adjustment
      if (newIndex >= maxIndex) newIndex = minIndex
      if (newIndex < minIndex) newIndex += maxIndex
      const newImage = images[newIndex]
      onChange( newImage ? newImage.id : null )
    }
  }


  return <>
    <div className={classes} style={{width, height: pickerHeight}}>
      <div className="image-slider" style={{width, height: pickerHeight}}>
        <div className="images" style={styles}>
          {
            images.map((img, i) => (
              <div className="img" key={img.id} style={imageStyle(img, i)} >

                { img.rebranding &&
                  <div className="absolute-full flex flex-center z-2 cursor-default">
                    <div className="flex-col flex-center bg-black absolute-fill opacity-30"></div>
                    <div className="flex-col flex-center bg-white rounded shadow-xl p-4 z-1">
                      <div className="flex-col text-center gap-1">
                        <LoadingSpinner/>
                        <div className="gray">
                          Updating Image...
                        </div>
                      </div>
                    </div>
                  </div>
                }

                <img
                  alt="Post Image"
                  src={img.url}
                  className="prevent-long-press"
                  onContextMenu={e => e.preventDefault()}
                />
                <div className="sneeze-guard" onClick={imageClickAction(i)}>
                </div>
              </div>
            ))
          }
          { (imageCount === 0 && required) &&
            <div className="img no-image" style={imageStyle(null, 0)} >
              <div>
                This post needs an image.
              </div>
            </div>
          }
          { !required &&
            <div className="img no-image" style={imageStyle(null, images.length)} >
              <img src="/img/no-image.jpg" alt="No Image. Text-only Post" className="fit-contents" />
            </div>
          }
        </div>

        <div className="top-controls spread">
          <div className="frame-stepper">
            {
              frames.map((_f, i) => (
                <div className={classNames("frame", {current: i === index})} key={i}/>
              ))
            }
          </div>
        </div>

        { showDelete &&
          <button className="delete-image button" onClick={onRemove}>
            <div className="delete-image-button-background"></div>
            <div className="delete-image-button-icon">
              <DeleteIcon/>
            </div>
          </button>
        }
        { showPrevButton &&
          <button onClick={onPrev} className="prev-button">
            <div className="button-background"/>
            <div className="button-icon">
              <ArrowIcon direction="left" color="black" />
            </div>
          </button>
        }
        { showNextButton &&
          <button onClick={onNext} className="next-button">
            <div className="button-background"/>
            <div className="button-icon">
              <ArrowIcon direction="right" color="black" />
            </div>
          </button>
        }
      </div>
    </div>

    <style jsx>{`
      .image-picker {
        position: relative;
        max-height: 500px;
        transition: all 400ms;
      }
      .image-picker.collapsed {
        opacity: 0;
      }
      .image-picker button {
        border: none;
      }
      .image-picker button:disabled {
        cursor: not-allowed;
      }
      .sneeze-guard {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0);
      }
      .prev-button, .next-button {
        position: absolute;
        color: #fff;
        background-color: transparent;
        font-size: 1.5rem;
        font-weight: bold;
        cursor: pointer;
        transition: all 350ms;
        visibility: hidden;
        top: 0;
        bottom: 20px;
      }
      .button-background {
        width: 52px;
        display: flex;
        align-items: center;
        transition: opacity 150ms;
        transform: scale(90%);
        height: 40px;
      }
      .button-icon {
        position: absolute;
        top: 46px;
        filter: drop-shadow(0 1px 2px rgba(0,0,0,0.3));
      }
      .prev-button .button-icon {
        left: 14px;
      }
      .next-button .button-icon {
        right: 14px;
      }
      @media only screen and (min-width: 1200px) {
        .prev-button:hover .button-background,
        .next-button:hover .button-background {
          opacity: 0.6;
        }
      }
      .prev-button {
        left: -50px;
        padding-left: 0;
        border-left-width: 0px !important;
      }
      .next-button {
        right: -50px;
        padding-right: 0;
        border-right-width: 0px !important;
      }
      .edit-mode .prev-button,
      .edit-mode .next-button {
        visibility: visible;
        transition: all 400ms;
      }
      .edit-mode .prev-button {
        left: 0;
      }
      .edit-mode .next-button {
        right: 0;
      }
      .image-slider {
        overflow: hidden;
        position: relative;
        background-color: white;
        transition: all 400ms;
      }
      .images {
        display: relative;
        position: absolute;
        top: 0;
        bottom: 0;
        transition: all 400ms;
      }
      .edit-mode .images {
        transform-origin: top left;
        top: ${topMargin}px;
      }
      .images .img {
        position: absolute;
        top: 0;
        transition: all 400ms;
        transform-origin: top left;
        filter: drop-shadow(0 2px 3px rgba(0,0,0, 0.2));
      }
      .edit-mode .images .img {
        border: 1px solid #c9c9c9;
        transform: scaleX(${scaleCurrentImg}) scaleY(${scaleCurrentImg});
      }
      .top-controls {
        align-items: flex-start;
        opacity: 0;
        width: 100%;
        margin: 0 auto;
        transition: opacity 250ms;
        position: absolute;
        top: 4px;
      }
      .edit-mode .top-controls {
        transition: opacity 150ms 200ms;
        opacity: 1;
      }
      .delete-image {
        padding: 0px 10px;
        line-height: 0.5rem;
        font-size: 0.8rem;
        position: absolute;
        bottom: 16px;
        left: 52px;
        height: 28px;
        color: #ff0000;
        background-color: transparent;
        z-index: 1;
        filter: drop-shadow(0 1px 2px rgba(0,0,0,0.5));
        opacity: 0;
        transition: opacity 250ms;
        cursor: pointer;
      }
      .edit-mode .delete-image {
        opacity: 0.8;
        transition: opacity 250ms 150ms;
      }
      .edit-mode .delete-image:hover {
        opacity: 1;
      }
      .edit-mode .delete-image:hover :global(.icon) {
        color: white
      }
      .delete-image-button-background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: white;
      }
      .edit-mode .delete-image:hover .delete-image-button-background {
        background-color: red;
      }
      .delete-image-button-icon {
        padding: 0.4rem;
        position: relative;
      }
      .frame-stepper {
        display: flex;
        padding: 0 6px;
        background-color: transparent;
        align-items: center;
        height: 24px;
        margin: 0 auto;
      }
      .frame {
        background-color: white;
        border: 1px solid #999;
        width: 8px;
        height: 8px;
        margin: 0 2px;
        transition: background-color 250ms;
      }
      .frame.current {
        background-color: #6FBE44;
      }
      .no-image {
        background-color: #FAFAFA !important;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        transition: color 250ms, background-color 250ms;
      }
      .no-image h2 {
        font-weight: bold;
        font-size: 2rem;
        color: #aaa;
      }
      .no-image {
        font-size: 1.4rem;
        color: #999;
        margin: 0rem 0;
      }
      .no-image img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
      .no-animation, .no-animation * {
        transition: none !important;
      }

    `}</style>
  </>

}
