import { normalizeCaption, pluralCount, captionToTweets, captionLength } from "@/lib/string";``
import { humanTime } from "@/src/misc";
import rpc, { rpcTask } from "@/src/rpc";
import { sendMessage, useMessage } from "@/src/useMessage";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import EditIcon from "./icons/EditIcon";
import PostImagePicker from "./PostImagePicker";
import Modal from "./Modal";
import SocialIcon from "./icons/legacy/SocialIcon";
import StyledCaption from "./StyledCaption";
import TimePicker from "./TimePicker";
import CheckIcon from "./icons/CheckIcon";
import UploadIcon from "./icons/UploadIcon";
import LoadingOverlay from "./LoadingOverlay";
import FileUploader from "./FileUploader"
import useDeviceInfo from "@/src/useDeviceInfo";
import DownloadIcon from "./icons/DownloadIcon";
import SplitPostsIcon from "./icons/SplitPostsIcon";
import UnifyPostsIcon from "./icons/UnifyPostsIcon";
import ShareScreen from "./ShareScreen";
import withLoading from "@/src/withLoading";
import { uniq } from "@/lib/array";
import AutoLink from "./AutoLink";
import PlayIcon from "./icons/PlayIcon";
import PauseIcon from "./icons/PauseIcon";
import LoadingSpinner from "./LoadingSpinner";
import ProgressBar from "./ProgressBar";
import useCurrentOrg from "@/src/useCurrentOrg";
import SparklesIcon from "./icons/SparklesIcon";
import useRpc from "@/src/useRpc";
import ShareIcon from "./icons/ShareIcon";
import CommentIcon from "./icons/CommentIcon";
import HandThumbUpIcon from "./icons/HandThumbUpIcon";
import ChartIcon from "./icons/ChartIcon";
import EyeIcon from "./icons/EyeIcon";
import Link from "next/link";
import { useAppRouter } from "@/src/useAppRouter";
import ImageEditor from "./ImageEditor";


export default function PostEditor ({post, readonly, imageRequired, destinations, socialAccountsConnected, allowExport, allowPublish, showDeletedAfter, onEditDestinations, onDestinationsChange, onPublish, onChange, handleSplit, handleUnify, onReloadMetrics, onRemovePlatforms, loadingMetrics, wasPublished}) {
  const {mobile} = useDeviceInfo()

  const router = useAppRouter()

  const captionInputRef = useRef(null)
  const methodsRef      = useRef({})
  const videoPlayerRef  = useRef(null)

  const org = useCurrentOrg()
  const [aiCaptionAllowed] = useRpc('users.canAccess', {feature: 'aiCaption'})

  const canGenerateImage = post?.id && org?.aiEnabled

  const date = post.date

  const showTimePicker = !readonly

  // Set this to false to social engagement metrics that have zero values
  const showZeroMetrics = false

  const metricForViews    = post.metricForViews    || 0
  const metricForLikes    = post.metricForLikes    || 0
  const metricForComments = post.metricForComments || 0
  const metricForShares   = post.metricForShares   || 0
  const metricsUpdatedAt  = post.metricsUpdatedAt

  const statsButtonAvailable = !!org?.metricsEnabled && wasPublished

  const [ caption,          setCaption          ] = useState(post.caption || '')
  const [ imageId,          setImageId          ] = useState(post.imageId)
  const [ images,           setImages           ] = useState(post.images || [])
  const [ scheduledAt,      setScheduledAt      ] = useState(post.scheduledAt)
  const [ saving,           setSaving           ] = useState(false)
  const [ captionMissing,   setCaptionMissing   ] = useState(false)
  const [ showSavingStatus, setShowSavingStatus ] = useState(false)
  const [ editingImage,     setEditingImage     ] = useState(false)
  const [ pickingImage,     setPickingImage     ] = useState(false)
  const [ loadingImage,     setLoadingImage     ] = useState(false)
  const [ generatingImage,  setGeneratingImage  ] = useState(false)
  const [ imageGenProgress, setImageGenProgress ] = useState(0)
  const [ editingText,      setEditingText      ] = useState(false)
  const [ editingTime,      setEditingTime      ] = useState(false)
  const [ showShare,        setShowShare        ] = useState(false)
  const [ showPublishError, setShowPublishError ] = useState(false)
  const [ showTwitterThread, setShowTwitterThread ] = useState(false)
  const [ showReloadMessage, setShowReloadMessage ] = useState(false)
  const [ generatingCaption, setGeneratingCaption ] = useState(false)
  const [ postTopic,         setPostTopic         ] = useState('')

  const canGenerateCaption = !readonly && !editingText && aiCaptionAllowed && !post?.caption && !captionMissing
  const canEditImage = !readonly && imageId && (org?.id === 267 || org?.id === 1591)

  // If the post was not sent, and it didn't have any destinations, then there
  // is no value in showing any UI when they click on the "No Destinations" UI.
  const destinationsDisabled = readonly && !socialAccountsConnected
  const canClickOnDestinations = !destinationsDisabled && !!onEditDestinations

  function handleEditDestinations () {
    if (canClickOnDestinations) onEditDestinations()
  }

  const containerRef = useRef()
  const [containerWidth, setContainerWidth] = useState(0)

  const hasImages = images.length > 0

  const platforms = uniq(destinations.map(d => d.platform))
  const forInstagram = platforms && platforms.includes('instagram')

  useMessage('captionMissing', () => {
    setCaptionMissing(true)
  })

  useEffect(() => {
    if (editingText && captionMissing) {
      setCaptionMissing(false)
    }
  }, [editingText, captionMissing])

  useEffect(() => {
    calculateWidth()

    window.addEventListener('resize', calculateWidth)
    return () => {
      window.removeEventListener('resize', calculateWidth)
    }

    function calculateWidth () {
      const width = containerRef.current?.clientWidth
      // FIXME: The loading is causing this, maybe move this into a separate component?
      if (width === undefined) {
        setTimeout(calculateWidth, 100)
        return
      }
      setContainerWidth(width)
    }
  }, [])

  useEffect(() => {
    if (!editingText) return

    setPickingImage(false)
  }, [editingText])


  const maxWidth = 400 // max size

  let widgetWidth = maxWidth
  if (containerWidth) widgetWidth = Math.min(widgetWidth, containerWidth)

  let [width, height] = [1080, 1080]

  let imageSizeError = ''
  let showWatermarkNote = false
  let isVideo = false

  if (hasImages) {
    const image = images.find(img => img.id === imageId) || images[0]
    width  = image.width  || width
    height = image.height || height

    if (image) {
      isVideo = image.style === 'video'

      showWatermarkNote = !!image.watermark

      // =======================================================================
      // Instagram does not allow images outside of specific aspect ratios.
      //
      // According to the official Instagram documentation, the aspect ratio
      // limits for images are 1.91:1 and 4:5 (in decimals: 1.91 and 0.8), but
      // the real limits are 320:166 and 320:574 (1.9277 and 0.5575)
      //
      // https://developers.facebook.com/docs/instagram-api/reference/ig-user/media/
      //
      // The real limits were found by testing different sized images with the
      // Instagram API. From the error messages, it looks like they scale the
      // image dimensions to a width of 320px and then reject any images with a
      // (scaled) height that is above 574 or below 166.
      // =======================================================================
      const widestRatio  = 1.9277 // 320 x 166 works, 320 x 165 fails
      const tallestRatio = 0.5575 // 320 x 574 works, 320 x 575 fails
      const aspectRatio = image.width / image.height
      const mediaType = image.style === 'video' ? 'video' : 'image'
      if (forInstagram) {
        if (aspectRatio > widestRatio)  imageSizeError = `This ${mediaType} is TOO WIDE for Instagram. Please crop the ${mediaType} to reduce the width.`
        if (aspectRatio < tallestRatio) imageSizeError = `This ${mediaType} is TOO TALL for Instagram. Please crop the ${mediaType} to reduce the height.`
      }

      // Instagram allows images under 200px, but they recommend at least 320
      // pixels for the width. Facebook also seems to allow very small images,
      // and Twitter hasn't complained about images as low as 211 pixels wide.
      // So, smaller images are technically allowed, but they look bad.
      if (image.width < 320) {
        imageSizeError = `This ${mediaType} is too small. It will look fuzzy when published. Please upload a larger ${mediaType}.`
      }
    }
  }

  let imageWidth = width
  let imageHeight = height

  const scaling = imageWidth / widgetWidth

  imageWidth /= scaling
  imageHeight /= scaling

  // Make sure that a very short image won't collapse the UI too far
  imageHeight = Math.max(imageHeight, 150)

  const wideLayout = containerWidth > imageWidth * 2

  function endTextEdit () {
    setEditingText(false)
    setCaption(normalizeCaption(caption))
  }

  function removeImage () {
    const index = images.findIndex(img => img.id === imageId)
    const newImages = images.filter(img => img.id !== imageId)
    setImages(newImages)

    const newIndex = Math.min(index, newImages.length - 1)
    setImageId(newImages[newIndex]?.id)

    if (newImages.length === 0) {
      setPickingImage(false)
    }
  }

  const [videoPlaying, setVideoPlaying] = useState(false)

  function playVideo (e) {
    e.preventDefault()
    e.stopPropagation()

    if (!videoPlayerRef.current) return

    videoPlayerRef.current.currentTime = 0
    videoPlayerRef.current.play()

    // When the video ends, switch the video UI back into a preview image
    videoPlayerRef.current.addEventListener('ended', stopVideo)

    setVideoPlaying(true)
  }

  function stopVideo (e) {
    e.preventDefault()
    e.stopPropagation()

    if (!videoPlayerRef.current) return

    videoPlayerRef.current.pause()

    setVideoPlaying(false)
  }


  const defaultDestination = destinations.length === 1 && destinations[0].platform === 'social'
  const activeDestinations = destinations.filter(d => d.targetId)
  const destinationName = activeDestinations.length === 1 ? activeDestinations[0].name : ''

  const captionErrors = CaptionErrors(caption, platforms)
  const captionWarnings = CaptionWarnings(caption, platforms, () => setShowTwitterThread(true))

  const canChangePhoto = !readonly && !post.publishedAt

  const currentImage = images.find(img => img.id === imageId)
  const imageNotReady = currentImage && !currentImage.readyToPublish

  const maxHeight = wideLayout ? imageHeight : 'initial'

  const [statsVisible, setStatsVisible] = useState(false)


  async function showStats () {
    onReloadMetrics()
    setStatsVisible(true)
  }


  async function handleExport () {
    setTimeout(() => setShowShare(true), 100)
  }


  async function undoDelete () {
    await rpc('posts.restore', {id: post.id})
    onDestinationsChange()
    sendMessage('refreshCalendar')
  }



  function openFilePicker () {
    methodsRef.current.open && methodsRef.current.open()
  }


  function removeInstagram (e) {
    e.preventDefault()
    e.stopPropagation()

    onRemovePlatforms(['instagram'])
  }


  function handleTapOnImage () {
    if (readonly) return
    if (pickingImage) return

    if (hasImages) {
      setPickingImage(true)
    } else {
      openFilePicker()
    }
  }


  function handleEditImage (e) {
    e.stopPropagation()
    e.preventDefault()

    if (readonly) return

    setEditingImage(true)
  }


  async function updateImageId (newImageId) {
    setImageId(newImageId)
  }


  const readyToSave = useRef(false)

  useEffect(() => {
    // Used to avoid saving until AFTER the component finishes mounting
    if (!readyToSave.current) {
      readyToSave.current = true
      return
    }

    if (readonly) return
    if (!post.id) {
      if (window.localStorage) {
        window.localStorage.setItem('unsavedCaption', caption)
      }
      return onChange({imageId, caption, scheduledAt})
    }

    setSaving(true)

    // Debounce the save to once per second.
    const timer = setTimeout(save, 1000)
    // TODO: Need to make sure the save fires when unmounting
    return () => clearTimeout(timer)

    async function save () {
      await rpc('posts.update', {
        id: post.id,
        scheduledAt,
        caption,
        imageId,
        allImageIds: images.map(img => img.id),
      })
      sendMessage('refreshCalendar')
      setSaving(false)
    }
  }, [caption, scheduledAt, imageId])


  useEffect(() => {
    let timer = 0

    if (saving) {
      setShowSavingStatus(true)
    } else {
      timer = setTimeout(() => setShowSavingStatus(false), 8000)
    }

    if (timer) return () => clearTimeout(timer)
  }, [saving])


  async function updateTime (newTime) {
    setEditingTime(false)

    if (newTime === 'now') {
      await handlePublish()
      withLoading(async () => {
        await onPublish()
      })
    } else {
      setScheduledAt(newTime)
    }
  }


  const [publishing, setPublishing] = useState(false)


  async function handlePublish (e) {
    if (!allowPublish) {
      setShowConnectFirst(true)
      return
    }

    try {
      setPublishing(true)
      try {
        const task = await rpcTask('social.publishPost', {post_id: post.id})
        await task.done()
        sendMessage('postPublished')
      } catch (err) {
        if(err.type === 'UserFacingError') {
          sendMessage('error', err.toString())
        } else {
          sendMessage('error', 'An unexpected publishing error occurred. Please try again later.')
        }
      }
    } finally {
      setPublishing(false)
    }
  }


  async function onUpload (fileRecords, thumbnailFile) {
    setLoadingImage(true)
    try {
      const fileIds = fileRecords.map(f => f.id)

      const task = await rpcTask('images.createFromFilesTask', {
        fileIds,
        platform: 'instagram',
        style: 'unknown',
        type: 'customer',
        thumbnailFileId: thumbnailFile?.id,
      }, {throwErrors: true})

      const image = await task.result()
      setImages(prev => [...prev, image])
      setImageId(image.id)
    } catch (err) {
      console.log(err)
      sendMessage('error', "File upload failed.")
    }
    setLoadingImage(false)
  }


  useEffect(() => {
    if (!post?.id) return
    if (!org?.aiEnabled) return

    let wasGeneratingImage = false

    loadTasks()

    async function loadTasks () {
      const tasks = await rpc('posts.unresolvedTasks', {postId: post.id})

      const aiGenTask = tasks?.find(t => t.name === "GeneratePostImageTask")

      if (aiGenTask) {
        wasGeneratingImage = true

        const seconds = Math.floor((Date.now() - new Date(aiGenTask.createdAt).getTime()) / 1000)
        setImageGenProgress(Math.min(seconds, 90))
        setGeneratingImage(true)

        setTimeout(loadTasks, 10 * 1000)
      } else if (wasGeneratingImage) {
        setGeneratingImage(false)
        setShowReloadMessage(true)
      }
    }
  }, [])


  async function aiGenerateCaption(e) {
    e.preventDefault()
    e.stopPropagation()

    setGeneratingCaption(true)

    try {
      const task = await rpcTask('posts.generateAiCaptionInBackground', {topic: postTopic})

      const newCaption = await task.result()
      setCaption(newCaption)

    } catch (err) {
      console.log(err)
      sendMessage('error', "AI caption generation failed.")
    }
    setGeneratingCaption(false)
  }


  async function aiGenerateImage(e) {
    e.preventDefault()
    e.stopPropagation()

    setImageGenProgress(0)
    setGeneratingImage(true)

    try {
      const task = await rpcTask('posts.generateAiImageInBackground', {postId: post.id})

      sendMessage('generatingImage')
      const image = await task.result()

      setImages(prev => [...prev, image])
      setImageId(image.id)
    } catch (err) {
      console.log(err)
      sendMessage('error', "AI image generation failed.")
    }
    setGeneratingImage(false)
  }


  const imageGenTimerRef = useRef(null)

  useEffect(() => {
    if (generatingImage) {

      // Fill up the progress bar slowly
      imageGenTimerRef.current = setInterval(() => {
        setImageGenProgress(v => Math.min(v + 1, 98))
      }, 250)
    } else {
      clearInterval(imageGenTimerRef.current)
    }
  }, [generatingImage])


  return <>
    <div className={classNames("full-width relative")} ref={containerRef}>
      { publishing &&
        <div className="absolute-full flex flex-center z-4">
          <div className="flex-col flex-center bg-white absolute-fill opacity-30"></div>
          <div className="flex-col flex-center bg-white rounded shadow-xl p-4 z-1">
            <div className="flex-col text-center gap-1">
              <LoadingSpinner/>
              <div className="text-lg gray">
                Publishing...
              </div>
              { currentImage?.style == 'video' &&
                <div className="text-sm gray">
                  This may take a few minutes.
                </div>
              }
            </div>
          </div>
        </div>
      }
      <div className={classNames("mx-auto full-width relative")} style={{maxWidth: wideLayout ? maxWidth * 2 : maxWidth}}>
        { post.deletedAt && post.deletedAt > showDeletedAfter ?
          <div className="flex-row rounded red bg-red border-red">
            <div className="flex-grow flex-row gap-2 p-2 px-4">
              { activeDestinations.map(destination => (
                <div key={destination.id} title={destination.name} className="flex">
                  <SocialIcon platform={destination.platform}/>
                </div>
              ))}
              Post Deleted
            </div>
            <button className="button" onClick={undoDelete}>
              Undo
            </button>
          </div>
        : post.deletedAt ?
          null
        :
          <div className={classNames("flex-col gap-1 transition-slow", {'gap-1': true})}>
            <div className={classNames("flex-col gap-1px transition-slow bg-none", {'gap-1': true})}>

              <div className={classNames("button-row flex-row flex-grow flex-center transition-slow gap-1px", {'gap-1': true})}>

                <div className="flex-row flex-grow gap-1 overflow-hidden">
                  <div key="social" className="overflow-hidden full-width flex-grow flex-row">
                    <div className={classNames("overflow-hidden flex-row fit-content p-2 gap-2", {'bg-hover': canClickOnDestinations, 'pointer': canClickOnDestinations, 'cursor-not-allowed': !canClickOnDestinations})} onClick={handleEditDestinations} >
                      { destinations.length === 0 ?
                        <>
                          { post.platform && post.platform !== 'social' ?
                            <SocialIcon platform={post.platform}/>
                          :
                            <div className="flex flex-row gap-2 blue bold">
                              Pick Accounts
                            </div>
                          }
                        </>
                      : defaultDestination ?
                        <>
                          { readonly ?
                            <div className="flex flex-row gap-2 gray">
                              No Destination
                            </div>
                          : socialAccountsConnected ?
                            <div className="flex flex-row gap-2 blue bold">
                              Pick Accounts
                            </div>
                            :
                            <div className="flex flex-row gap-2 blue bold">
                              Connect Account
                            </div>
                          }
                        </>
                      :
                        <>
                          { activeDestinations.sort().map(destination => (
                            <div key={destination.id} title={destination.name} className="flex">
                              <SocialIcon platform={destination.platform}/>
                            </div>
                          ))}
                          <span className="nowrap small overflow-hidden ellipsis" title={destinationName}>
                            {destinationName}
                          </span>
                        </>
                      }
                    </div>

                    { handleSplit ?
                      <div className="flex-row fit-content p-2 pointer bg-hover gap-2" onClick={handleSplit} title="Create a separate post for each platform">
                        <SplitPostsIcon/>
                      </div>
                    : handleUnify ?
                      <div className="flex-row fit-content p-2 pointer bg-hover gap-2" onClick={handleUnify} title="Merge posts into a single post for all platforms">
                        <UnifyPostsIcon/>
                      </div>
                    :
                      null
                    }
                  </div>

                </div>

                <div className="flex flex-shrink justify-flex-end flex-center mr-1">

                  <div className="flex-row gap-1" >
                    { statsButtonAvailable &&
                      <div className="flex p-2 pointer bg-hover" onClick={showStats}>
                        <ChartIcon/>
                      </div>
                    }
                    { allowExport &&
                      <div className="flex p-2 pointer bg-hover" onClick={handleExport}>
                        <DownloadIcon/>
                      </div>
                    }
                  </div>


                  <div>
                    { showTimePicker &&
                      <div
                        className={classNames("nowrap time-value p-2 cursor-default", {'bg-hover': !readonly, pointer: !readonly})}
                        onClick={() => setEditingTime(true)}
                        disabled={readonly}
                      >
                        { scheduledAt ?
                          <span>{ humanTime(scheduledAt) }</span>
                        :
                          <span className="blue bold">Set Time</span>
                        }
                      </div>
                    }
                  </div>

                </div>
              </div>

              <div className={classNames("image-and-caption flex gap-1", {'flex-col': !wideLayout})} style={{maxHeight}}>

                <div className="flex-grow relative flex-col" style={{flexBasis: wideLayout ? imageWidth : '', maxWidth: wideLayout ? imageWidth : ''}}>
                  <div className="relative overflow-auto-y rounded border-gray full-height">
                    <div className={classNames("caption-area overflow-hidden-y full-height bg-white flex-grow relative", {'bg-disabled': readonly})}>
                      <div className={classNames("relative full-width min-full-height bg-white", {'caption-missing': captionMissing})}>
                        { captionMissing &&
                          <div className="absolute-full flex flex-center">
                            <div className="flex-col gap-2 text-center">
                              <div className="text-lg bold">
                                Caption is Required
                              </div>
                              <div className="text-center">
                                Tap here to add text to this post.
                              </div>
                            </div>
                          </div>
                        }
                        <div className={classNames("styled-caption-wrapper min-full-height full-width min-full-width", {'caption-missing': captionMissing})} style={{minHeight: '10rem'}}>
                          <StyledCaption caption={caption || ' '}/>
                          <div className={classNames("absolute top full-width left px-4 py-3 gray", {hidden: caption.length > 0})}>
                            { captionMissing ?
                              ''
                            : canGenerateCaption ?
                              'What do you want to post about?'
                            :
                              'Type your post here.'
                            }
                          </div>
                        </div>
                        <div className={classNames("absolute-fill full-height transition relative flex opacity-0", {'opacity-100': editingText})}>
                          <textarea
                            className={classNames("caption-textarea full-height full-width min-full-width resize-none overflow-hidden")}
                            value={caption}
                            onChange={e => setCaption(e.target.value)}
                            onFocus={() => setEditingText(true)}
                            onBlur={endTextEdit}
                            ref={captionInputRef}
                            disabled={readonly}
                          />
                        </div>
                      </div>
                    </div>

                    { canGenerateCaption &&
                      <div className="absolute top left right full-width" style={{top: '2.5rem'}}>
                        <div className="flex-col gap-2">
                          <form className="flex-row gap-1 py-2 px-3" onSubmit={aiGenerateCaption} >
                            <input
                              type="text"
                              className="input"
                              name="postTopic"
                              autoFocus
                              value={postTopic}
                              onChange={e => setPostTopic(e.target.value)}
                              placeholder="Topic (like: New Year's)"
                            />
                            <button className="button is-primary flex-row gap-1" disabled={!postTopic}>
                              <SparklesIcon/> Write It
                            </button>
                          </form>
                          <div className="text-left text-sm px-4">
                            <span className="subtle-link pointer py-2" onClick={e => {e.preventDefault(); captionInputRef.current.focus()}}>
                              Skip this, and write my own (from scratch).
                            </span>
                          </div>
                        </div>
                      </div>
                    }

                    { generatingCaption &&
                      <div className="absolute-full flex flex-center z-2">
                        <div className="flex-col flex-center bg-black absolute-fill opacity-30"></div>
                        <div className="flex-col flex-center bg-white rounded shadow-xl p-4 z-1">
                          <div className="flex-col text-center gap-1">
                            <LoadingSpinner/>
                            <div className="gray">
                              Generating Caption...
                            </div>
                          </div>
                        </div>
                      </div>
                    }


                    <div className="absolute bottom left right z-1 full-width">
                      { !readonly &&
                        <div className="flex spread">
                          <div/>
                          <div className="flex flex-row gap-1 p-2 m-1 flex-end absolute bottom right pointer-events-none rounded-xl opacity-80">
                            { showSavingStatus &&
                              <div className={classNames("tiny gray")}>
                                {saving ? 'Auto-Saving...' : "Saved"}
                              </div>
                            }

                            <div className="edit-icon flex opacity-40">
                              <EditIcon/>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                  <div className="text-sm">
                    { captionErrors.length > 0 &&
                      <div className="full-width mt-1">
                        <div className="rounded red is-error py-2 px-3 bg-red border-red opacity-95">
                          { captionErrors.map(err => err) }
                        </div>
                      </div>
                    }

                    { captionWarnings.length > 0 &&
                      <div className="full-width mt-1">
                        <div className="rounded orange py-2 px-3 bg-yellow border-yellow opacity-95">
                          { captionWarnings.map(err => err) }
                        </div>
                      </div>
                    }
                  </div>
                </div>

                <Modal visible={statsVisible} onClickOutside={() => setStatsVisible(false)}>
                  <div className="p-4 px-5 flex-col gap-4" style={{maxWidth}}>
                    <div className="text-lg bold text-center">
                      Post Performance
                    </div>

                    <div className="relative">

                      <LoadingOverlay visible={loadingMetrics}/>

                      { loadingMetrics ?
                        <div className="flex-col flex-align-center gap-2">
                          <div style={{minHeight: 155, minWidth: 155}}>
                          </div>
                        </div>
                      : !metricsUpdatedAt ?
                        <div className="flex-col flex-align-center gap-3 text-center gap-2 max-width-320">
                          <p>
                            No metrics available.
                          </p>
                          <p>
                            <Link href={router.path('social', {highlight: 'accounts'})} >
                              <a className="link">
                                Check your social accounts
                              </a>
                            </Link> to make sure you
                            have the permissions for analytics.
                          </p>
                        </div>
                      : !showZeroMetrics && !metricForViews && !metricForLikes && !metricForComments && !metricForShares ?
                        <div className="flex-col flex-align-center gap-3 text-center gap-2 max-width-320">
                          <p>
                            No metrics available, yet.
                          </p>
                          <p>
                            Please check back in a few days<br/> to see how this post is performing.
                          </p>
                        </div>
                      :
                        <div className="grid grid-3-cols gap-row-3 gap-col-2" style={{minHeight: 50}}>

                          { showZeroMetrics || metricForViews ?
                            <>
                              <div className="text-right">
                                <EyeIcon />
                              </div>
                              <div className="text-right">{metricForViews.toLocaleString()}</div>
                              <span className="flex-grow">View{ metricForViews === 1 ? '' : 's'}</span>
                            </>
                          :
                            null
                          }

                          { showZeroMetrics || metricForLikes ?
                            <>
                              <div className="text-right">
                                <HandThumbUpIcon />
                              </div>
                              <div className="text-right">{metricForLikes.toLocaleString()}</div>
                              <span className="flex-grow">Like{ metricForLikes === 1 ? '' : 's'}</span>
                            </>
                          :
                            null
                          }

                          { showZeroMetrics || metricForComments ?
                            <>
                              <div className="text-right">
                                <CommentIcon />
                              </div>
                              <div className="text-right">{metricForComments.toLocaleString()}</div>
                              <span className="flex-grow">Comment{ metricForComments === 1 ? '' : 's'}</span>
                            </>
                          :
                            null
                          }

                          { showZeroMetrics || metricForShares ?
                            <>
                              <div className="text-right">
                                <ShareIcon />
                              </div>
                              <div className="text-right">{metricForShares.toLocaleString()}</div>
                              <span className="flex-grow">Share{ metricForShares === 1 ? '' : 's'}</span>
                            </>
                          :
                            null
                          }
                        </div>
                      }
                    </div>

                    <div className="flex-row flex-center mt-2">
                      <button className="button is-primary" onClick={() => setStatsVisible(false)}>
                        Close
                      </button>
                    </div>
                  </div>
                </Modal>


                <Modal visible={showReloadMessage} onClickOutside={() => setShowReloadMessage(false)}>
                  <div className="p-4 flex-col gap-3 text-center" style={{maxWidth}}>
                    <div className="text-lg bold">
                      Image Generation Complete
                    </div>
                    <p className="text-sm gray mb-2">
                      The AI has finished generating the image for this post. Reload the page to see the new image.
                    </p>
                    <div className="flex-row flex-center">
                      <button className="button is-primary" onClick={() => window.location.reload()}>
                        Refresh Page
                      </button>
                    </div>
                  </div>
                </Modal>


                <Modal visible={showTwitterThread} onClickOutside={() => setShowTwitterThread(false)}>
                  <div className="p-4 flex-col gap-3" style={{maxWidth}}>
                    <div className="text-lg bold">
                      Twitter Thread Preview
                    </div>
                    <p className="text-sm gray mb-2">
                      Because this post is longer than 280 characters, it will be split into a thread of tweets, like this...
                    </p>
                    <div className="flex flex-col gap-3 twitter-thread">
                      {captionToTweets(caption).map((tweet, i) => (
                        <div key={i}>
                          <div className="text-sm gray bold p-1">
                            Tweet #{i+1}:
                          </div>
                          <div className="bg-white rounded border-gray">
                            <div className="styled-caption-wrapper">
                              <StyledCaption caption={tweet || ' '}/>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="flex-row flex-center">
                      <button className="button is-primary" onClick={() => setShowTwitterThread(false)}>
                        Close
                      </button>
                    </div>
                  </div>
                </Modal>



                <div className="relative">
                  <FileUploader onUpload={onUpload} methods={methodsRef.current}>
                    <div
                      className={classNames("image image-picker-container bg-white flex-grow rounded border-gray  overflow-hidden", {pointer: !pickingImage && !readonly})}
                      style={{height: imageHeight, flexBasis: wideLayout ? imageWidth : ''}}
                      onClick={handleTapOnImage}
                    >
                      <LoadingOverlay visible={loadingImage}/>

                      { generatingImage &&
                        <div className="absolute-full flex flex-center z-2 cursor-default" onClick={e => e.stopPropagation()}>
                          <div className="flex-col flex-center bg-black absolute-fill opacity-30"></div>
                          <div className="flex-col flex-center bg-white rounded shadow-xl p-4 z-1">
                            <div className="flex-col text-center gap-1">
                              <LoadingSpinner/>
                              <div className="gray">
                                Generating Image...
                              </div>
                              <ProgressBar value={imageGenProgress}/>
                            </div>
                          </div>
                        </div>
                      }

                      <PostImagePicker
                        editMode={pickingImage}
                        imageId={imageId}
                        images={images}
                        width={imageWidth}
                        height={imageHeight}
                        onChange={updateImageId}
                        onRemove={removeImage}
                        disabled={readonly}
                        onUpload={onUpload}
                      />

                      { canEditImage &&
                        <div className={classNames("flex-row flex-center border border-gray pointer edit-button absolute top right transition z-1 m-4 p-2 bg-white rounded-xl opacity-60 hover_opacity-100 shadow-lg", {hidden: pickingImage})} style={{zIndex: 22}} onClick={handleEditImage}>
                          <EditIcon/> Edit
                        </div>
                      }

                      { !readonly &&
                        <div className={classNames("edit-icon absolute bottom right transition z-1 p-2", {'opacity-0': pickingImage, visible: pickingImage})}>
                          <EditIcon/>
                        </div>
                      }
                      <div className={classNames("image-edit-button-container absolute bottom left flex flex-center z-2 opacity-0 transition p-1", {'opacity-100': pickingImage, 'flex': pickingImage})}>
                        <button className="button image-edit-button blue shadow-lg" onClick={openFilePicker}>
                          <UploadIcon/>
                        </button>
                        <div className="button-tooltip tooltip-right">
                          Upload a new image
                        </div>
                      </div>

                      <div className={classNames("absolute bottom left right flex flex-center z-1 opacity-0 transition p-1 small light-gray pointer-events-none", {'opacity-100': pickingImage})}>
                        { mobile ?
                          <span>
                            &larr; Tap here to upload an image
                          </span>
                        :
                          <span>
                            Drag and drop images here to upload
                          </span>
                        }
                      </div>

                      <div className={classNames("image-edit-button-container absolute bottom right flex flex-center z-2 opacity-0 transition p-1", {'opacity-100': pickingImage, 'flex': pickingImage})}>
                        <button className="button image-edit-button green shadow-lg" onClick={() => setPickingImage(false)}>
                          <CheckIcon/>
                        </button>
                        <div className="button-tooltip tooltip-left">
                          Done editing image
                        </div>
                      </div>
                      { !!canChangePhoto &&
                        <div className={classNames("image-error", {visible: !!imageSizeError})}>
                          { imageSizeError &&
                            <span>
                              {imageSizeError}
                            </span>
                          }&nbsp;
                        </div>
                      }
                      { (!pickingImage && !imageId) &&
                        <div className="placeholder absolute top center full-width gray" style={{fontSize: 15}}>
                          { readonly ?
                            <div className="gray">This was a text-only post.</div>
                          :
                            <div className="flex-col gap-2">
                              <div>
                                <button className="button gap-2">
                                  <UploadIcon/>
                                  Upload an Image or Video
                                </button>
                              </div>
                              { !mobile &&
                                <div>...or drag and drop files here.</div>
                              }
                              { imageRequired &&
                                <div className="danger mt-6 mx-4">This post will NOT publish to Instagram unless you add <span className="nowrap">an image or video</span>.</div>
                              }
                              { canGenerateImage &&
                                <div>
                                  <button className="button is-primary gap-2 mt-6" onClick={aiGenerateImage}>
                                    🪄 Create Image with AI ✨
                                  </button>
                                </div>
                              }
                            </div>
                          }
                        </div>
                      }
                    </div>
                  </FileUploader>

                  <div className={classNames("absolute-full opacity-0 transition-slow z-down", {'z-2': videoPlaying, 'opacity-100': videoPlaying})} onClick={stopVideo}>
                    <video ref={videoPlayerRef} className="full-width" src={images.find(img => img.id === imageId)?.videoUrl} />
                  </div>

                  { (!pickingImage && isVideo) &&
                    <div className="absolute bottom full-width z-3 pointer-events-none" style={{fontSize: 15}}>
                      <div className="opacity-90 flex-row flex-center pointer-events-none">
                        { videoPlaying ?
                          <div className="clickable pointer-events-all fit-content margin-auto black bg-white px-3 py-2 rounded-lg bold m-3 opacity-70 shadow flex-row gap-1 transition hover_opacity-100" onClick={stopVideo}>
                            <PauseIcon/>
                            Pause
                          </div>
                        :
                          <div className="clickable pointer-events-all fit-content margin-auto black bg-white px-3 py-2 rounded-lg bold m-3 opacity-70 shadow flex-row gap-1 transition hover_opacity-100" onClick={playVideo}>
                            <PlayIcon/>
                            Play
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>

              { readonly ?
                null
              :
                <div className="flex-row flex-justify-end overflow-hidden transition-slow" style={{maxHeight: showWatermarkNote ? 45 : 0, opacity: showWatermarkNote ? 100 : 0}}>
                  <div className="gray text-sm text-center" style={{width: 400}}>
                    Social Jazz watermarks will be removed before publishing.
                  </div>
                </div>
              }
            </div>

          </div>
        }
      </div>
    </div>


    <Modal visible={editingImage}>
      <div className="p-4 flex-col gap-3" style={{maxHeight: '95vh', display: 'flex'}}>
        <ImageEditor
          org={org}
          image={images.find(img => img.id === imageId)}
          onCancel={() => setEditingImage(false)}
          onSave={newImage => {
            setImages(prev => prev.map(img => img.id === imageId ? newImage : img))
            setEditingImage(false)
          }}
        />
      </div>
    </Modal>

    <Modal visible={showShare} onClickOutside={() => setShowShare(false)}>
      <div className='content sharing-content padded' style={{maxWidth: 380}}>
        <ShareScreen post={post} />
        <div className="button-container margin-below mt-2">
          <button className="button" onClick={() => setShowShare(false)}>
            Done
          </button>
        </div>
      </div>
    </Modal>

    { editingTime &&
      <Modal visible={true}>
        <TimePicker
          onSubmit={updateTime}
          onClose={() => setEditingTime(false)}
          value={scheduledAt}
          date={date}
          preventPublish={!post.id || !allowPublish || imageNotReady}
        />
      </Modal>
    }

    { showPublishError &&
      <Modal visible={true}>
        <div className="p-4" style={{maxWidth: 320}}>
          <h2 className="bold text-lg mb-2">
            Publishing Error
          </h2>
          This post could not be published. <AutoLink text={post.publishErrorMessage}/>
          <div className="center mt-4">
            <button className="button is-primary" onClick={() => setShowPublishError(false)}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    }

    <style jsx>{`
      .image-error {
        position: absolute;
        top: 1rem;
        left: 17%;
        right: 17%;
        text-align: center;
        color: darkred;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 1rem;
        z-index: 1;
        opacity: 0;
        transition: all 400ms;
        pointer-events: none;
        margin-top: 0;
        border-radius: 0.5rem;
        box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.4);
      }

      .image-error.visible {
        opacity: 1;
        margin-top: 10px;
      }

      .bg-hover {
        border: 1px solid transparent;
        border-radius: 0.5rem;
        transition: all 150ms;
      }
      .bg-hover:hover {
        background-color: white;
        border-color: #ddd;
      }
      .image-and-caption {
        transition: max-height 400ms;
      }
      .image-edit-button-container .button:hover {
        background-color: #cce6f2;
      }
      .button-tooltip {
        transition: opacity 200ms;
        background-color: #1e1e1e;
        color: #fff;
        border-radius: 3px;
        box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.4);
        font-size: 0.9rem;
        font-weight: bold;
        padding: 0.5rem 1rem;
        z-index: 1;
        pointer-events: none;
        position: absolute;
        opacity: 0;
        white-space: nowrap;
      }
      .placeholder {
        padding-top: 1.5rem;
      }
      .image-edit-button-container button:hover + .button-tooltip {
        opacity: 0.8;
        transition: opacity 200ms 500ms;
      }
      .tooltip-left {
        right: 3.5rem;
      }
      .tooltip-right {
        left: 3.5rem;
      }
      .button-row {
        height: 42px;
        align-items: stretch;
      }
      .button-row button {
        height: 100%;
      }
      .image-edit-button {
        height: 42px;
        width: 42px;
        padding: 0;
        margin: 0.25rem;
      }
      .time-value {
        padding: 0;
        font-size: 1rem;
      }
      .not-phone { display: none }
      @media screen and (min-width: 375px) {
        .phone-only { display: none }
        .not-phone { display: contents }
      }
      .styled-caption-wrapper {
        /* Note the weird space! Needed to preventy jumpy behavior */
        content: attr(data-replicated-value) " ";
        color: #4a4a4a;
      }
      .caption-textarea,
      .styled-caption-wrapper {
        font-weight: bold;

        word-wrap: break-word;
        white-space: pre-wrap;

        /* Identical styling required! */
        border: 1px solid transparent;
        border-radius: 4px;
        margin: 0rem;
        padding: 0.75rem 1rem;
        font: inherit;

        /* This size is a good match for what's shown on the social sites */
        font-size: 15px;
      }
      {/* Extra padding on bottom to leave room for the "saving" status area */}
      .styled-caption-wrapper {
        padding-bottom: 2.2rem;
        background: white;
      }
      .caption-missing,
      .styled-caption-wrapper.caption-missing {
        background: #f8d7da;
      }
      .twitter-thread .styled-caption-wrapper {
        padding-bottom: 1rem;
      }
      .image-picker-container {
        transition: height 400ms;
      }
      .image-picker-container .edit-icon,
      .caption-area .edit-icon {
        opacity: 0.3;
      }
      .image-picker-container:hover .edit-icon,
      .caption-area:hover .edit-icon {
        opacity: 1;
      }


    `}</style>
  </>
}



//=============================================================================


function CaptionErrors(caption, platforms) {
  const MAX_INSTAGARM_HASHTAGS = 30

  const hashtags = (caption.match(/(^|\s)#[a-z0-9]+/ig) || [])

  const errors = []

  if (platforms.includes('instagram') && hashtags.length > MAX_INSTAGARM_HASHTAGS) {
    errors.push(
      <div key="too-many-instagram-hashtags">
        Too many hashtags for Instagram ({MAX_INSTAGARM_HASHTAGS} max).
      </div>
    )
  }

  return errors
}


function CaptionWarnings(caption, platforms, previewTwitterThread) {
  const MAX_TWEET_SIZE = 280

  const warnings = []
  const length = captionLength(caption)

  if (platforms.includes('twitter') && length > MAX_TWEET_SIZE) {
    warnings.push(
      <div key="too-long-for-twitter">
        This caption is {pluralCount(length - MAX_TWEET_SIZE, 'character')} over
        the limit for Twitter, so <a className="link" onClick={e => {e.preventDefault(); previewTwitterThread()}}>this post will be split into multiple tweets</a>.
      </div>
    )
  }

  return warnings
}
