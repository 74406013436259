import SetupCategories from "@/components/SetupCategories"
import useRpc from "@/src/useRpc";
import LoadingSpinner from "./LoadingSpinner";
import { useRef, useState } from "react";
import Modal from "./Modal";
import useCurrentOrg, { refreshCurrentOrg } from "@/src/useCurrentOrg";
import { pluralCount } from "@/lib/string";
import SetupFrequency from "./SetupFrequency";
import { sendMessage } from "@/src/useMessage";
import ProgressBar from "./ProgressBar";
import rpc, { rpcTask } from "@/src/rpc";
import { useAppRouter } from "@/src/useAppRouter";
import useHighlight from "@/src/useHighlight";


export default function TopicSettings({}) {

  const router = useAppRouter()

  const frequencyRef = useRef(null)
  useHighlight('frequency', frequencyRef)

  const topicsRef = useRef(null)
  useHighlight('topics', topicsRef)

  const org = useCurrentOrg()
  const [catalogs, error, reloadTopics] = useRpc('signup.catalogsAndCategories')
  const [editingTopics, setEditingTopics] = useState(false)
  const [showFrequency, setShowFrequency] = useState(false)
  const [rebuilding, setRebuilding] = useState(false)
  const [rebuildProgress, setRebuildProgress] = useState(0)
  const [selections, setSelections] = useState(null)
  const [frequency, setFrequency] = useState(null)
  const [showRebuildPrompt, setShowRebuildPrompt] = useState(false)

  const rebuildNeeded = !!(selections || frequency)

  const {postsPerWeekMin, postsPerWeekMax} = (org || {})
  const min = frequency?.min || postsPerWeekMin
  const max = frequency?.max || postsPerWeekMax
  let postsPerWeekText = ''
  if (min === max) {
    postsPerWeekText = `${pluralCount(min, 'post')} per week`
  } else {
    postsPerWeekText = `${min}-${pluralCount(max, 'post')} per week`
  }


  function promptRebuildTask () {
    setShowRebuildPrompt(true)
  }


  async function handleSubmitCategories (newSelections) {
    setEditingTopics(false)
    if (newSelections) {
      setSelections(newSelections)
      setShowRebuildPrompt(true)
    }
  }

  async function handleSubmitFrequency (newFrequency) {
    setShowFrequency(false)
    if (newFrequency) {
      if (newFrequency.min === postsPerWeekMin && newFrequency.max === postsPerWeekMax) {
        setFrequency(null)
      } else {
        setFrequency(newFrequency)
        setShowRebuildPrompt(true)
      }
    }
  }


  async function handleRebuildTask () {

    setRebuildProgress(0)
    setRebuilding(true)

    if (frequency) await rpc('signup.setFrequency', frequency)
    const task = await rpcTask('signup.subscribeToCatalogs', {selections})

    try {
      await task.done(percent => {
        // The task progress looks like it stalls at 80%, so we'll fake it a bit
        if (percent < 80) {
          setRebuildProgress(percent / 2)
        } else {
          setRebuildProgress(v => Math.min(v + 5, 95))
        }
      })
      setRebuildProgress(100)
      setSelections(null)
      setFrequency(null)
      setShowRebuildPrompt(false)
    } catch (err) {
      console.error(err)
      sendMessage('error', 'Failed to rebuild calendar')
    } finally {
      refreshCurrentOrg()
      sendMessage('refreshCalendar')
      await reloadTopics()
      setRebuilding(false)
      router.closePopover()
    }
  }

  return <>
    <div className="flex flex-col bg-f3f3f3 absolute-fill relative overflow-hidden">
      { rebuildNeeded &&
        <div className="p-2 shadow bg-yellow z-up mb-4 flex-col gap-1">
          <p className="px-1 py-1 text-center">
            When you are done making changes...
          </p>
          <div className="flex-row flex-center">
            <button className="button is-primary text-center" onClick={promptRebuildTask}>
              Update the Calendar
            </button>
          </div>
        </div>
      }
      <div className="px-5 py-2 shadow bg-white z-up" ref={frequencyRef}>
        { org &&
          <p className="px-1 py-1">
            You want <a className="link nowrap" onClick={() => setShowFrequency(true)}>
            {postsPerWeekText}</a> on the following topics:
          </p>
        }

      </div>

      <div className="flex-grow overflow-auto-y">
      { catalogs ?
        <SetupCategories catalogs={catalogs} selections={selections}/>
      :
        <div className="py-6">
          <LoadingSpinner size="medium" />
        </div>
      }
      </div>

      <div className="text-center bg-white left right py-2 shadow-top z-1">
        <button className="button is-primary" onClick={() => setEditingTopics(v => !v)} ref={topicsRef}>
          Change Topics
        </button>
      </div>
    </div>

    <Modal visible={showRebuildPrompt} onClickOutside={() => setShowRebuildPrompt(false)}>
      <div className="p-5 max-width-320 flex-col gap-4">
        <h2 className="text-xl bold text-center">
          Update the Calendar?
        </h2>
        <p>
          You've changed your post {
            [!!frequency && 'frequency', !!selections && 'topics'].filter(v => v).join(' and ')
          }.
        </p>
        <p>
          To apply this change, you will need to <span className="red bold">delete
          all of your (future) posts</span>, so we can rebuild your calendar.
        </p>
        <div className="flex flex-col gap-2 mt-2">
          <button className="full-width button is-danger" onClick={handleRebuildTask}>
            Delete Posts and Rebuild Calendar
          </button>
          <button className="full-width button" onClick={() => setShowRebuildPrompt(false)}>
            Not Now
          </button>
        </div>
      </div>
    </Modal>

    {/* -------------------------------------------------------------------------
      NOTE: We need to render this modal conditionally, instead of using the
      `visible` prop because we need it to use the `selections` prop when the
      customer has already made some selections.
    ------------------------------------------------------------------------- */}
    { editingTopics &&
      <Modal visible>
        <div className="flex-grow overflow-auto">
          <SetupCategories catalogs={catalogs} selections={selections} onSubmit={handleSubmitCategories} />
        </div>
      </Modal>
    }

    <Modal visible={showFrequency} onClickOutside={handleSubmitFrequency}>
      <div className="flex-grow overflow-auto">
        <SetupFrequency onSubmit={handleSubmitFrequency} defaultFrequency={{min, max}}/>
      </div>
    </Modal>

    <Modal visible={rebuilding}>
      <div className="p-4 text-center flex-col">
        <LoadingSpinner size="normal"/>
        <p className="gray mb-1">
          Rebuilding calendar...
        </p>
        <ProgressBar value={rebuildProgress}/>
      </div>
    </Modal>

  </>
}
