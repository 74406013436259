import { useState } from "react";
import classNames from "classnames";
import { sendMessage } from "@/src/useMessage";
import Link from "next/link";
import { useAppRouter } from "@/src/useAppRouter";
import useCurrentOrg from "@/src/useCurrentOrg";
import TrashIcon from "./icons/TrashIcon";
import SimpleDate from "@/lib/SimpleDate";
import DownloadIcon from "./icons/DownloadIcon";
import useDeviceInfo from "@/src/useDeviceInfo";

export default function HelpSettings () {

  const router = useAppRouter()

  const currentOrg = useCurrentOrg()
  const maxSocialAccounts = currentOrg?.maxSocialAccounts // This field is not in the database yet
  const canCreateExtraAccounts = false // We don't have this UI yet

  const {mobile, android} = useDeviceInfo()

  const [activeHowTo, setActiveHowTo] = useState()
  const [activeFAQ, setActiveFAQ] = useState()

  function getNextMonthsName () {
    let today = new SimpleDate()
    try {
      today = new SimpleDate(router.query.date)
    } catch (err) {
      // Error parsing date, swallow it and use today's date
    }
    const nextMonthName = today.addMonths(1).monthName
    return nextMonthName
  }

  function toggleHowTo (item) {
    setActiveHowTo(item === activeHowTo ? null : item)
  }

  function toggleFAQ (item) {
    setActiveFAQ(item === activeFAQ ? null : item)
  }

  const howto = []

  howto.push({
    question: <>Add a new post</>,
    answer:   <>Just tap any date on the calendar.<br/><br/>Then you can pick a premade post or create one from scratch.</>,
  })

  howto.push({
    question: <>Delete an upcoming post</>,
    answer:   <>Open the post, and tap the <TrashIcon/> button in the top-left corner.<br/><br/><span className="gray">Note: You can't delete posts in the past.</span></>,
  })

  if (!android) {
    howto.push({
      question: <>Move a post to a different day</>,
      answer:   <>Drag and drop posts to move them around the calendar.</>,
    })

    howto.push({
      question: <>Move a post to the next month</>,
      answer:   <>Drag your post to the box labeled <b>{getNextMonthsName()}</b> (in the bottom-right of the calendar), and <span className="bold nowrap">wait for 1 second</span> to switch to the next month.</>,
    })
  }

  howto.push({
    question: <>Pick what time a post will publish</>,
    answer:   <>Open the post and tap on the time in the upper-right. You can also <Link href={router.path('social', {highlight: 'time'})}><a className="link">change the time on all posts</a></Link>.</>,
  })

  if (!currentOrg?.aiEnabled) {
    howto.push({
      question: <>Change the number of posts per week</>,
      answer:   <>You can adjust your <Link href={router.path('content', {highlight: 'frequency'})}><a className="link">post frequency</a></Link>   in the Content Plan settings.</>,
    })

    howto.push({
      question: <>Pick which topics to post about</>,
      answer:   <>You can <Link href={router.path('content', {highlight: 'topics'})}><a className="link">change your topics</a></Link> under the Content Plan settings.</>,
    })
  }

  howto.push({
    question: <>Update my payment settings</>,
    answer:   <>You can manage your payment settings from the <Link href={router.path('subscription')}><a className="link">Billing page</a></Link>.</>,
  })

  howto.push({
    question: <>Cancel my account</>,
    answer:   <>You can cancel from the <Link href={router.path('subscription')}><a className="link">Billing page</a></Link>.<br/><br/>But before you quit, please <a className="link" onClick={() => sendMessage('help', {message: `I'm going to cancel my account, but I wanted to see if we could fix my problem first.`})}>let us know what we can do</a> to keep your around.</>,
  })


  const faq = []

  if (!currentOrg?.socialConnected) {
    faq.push({
      question: <>Why aren't posts being published?</>,
      answer:   <>Because we don't have anywhere to send those posts.<br/><br/>You need to <Link href={router.path('social', {highlight: 'connect'})}><a className="link">connect a social account</a></Link>.</>,
    })
  } else if (!currentOrg?.autoPublish) {
    faq.push({
      question: <>Why aren't posts being published?</>,
      answer:   <>The <Link href={router.path('social', {highlight: 'autopublish'})}><a className="link">Auto Publish</a></Link> feature is off.<br/><br/>You should turn it on, unless you want to manually publish posts each day.</>,
    })
  } else {
    faq.push({
      question: <>Why aren't posts being published?</>,
      answer:   <>Check the <Link href={router.path('social')}><a className="link">Publishing page</a></Link> to see if there are any issues.<br/><br/>If you need help, please <a className="link" onClick={() => sendMessage('help', {message: `My posts are NOT publishing.`})}>let us know</a>.</>,
    })
  }

  faq.push({
    question: <>What dimensions are best for a photo?</>,
    answer:   <>Square images (1080 x 1080 pixels) are ideal, but other sizes work fine. We will warn you if you upload a bad size (too small, too narrow, or too tall).</>,
  })

  faq.push({
    question: <>Are videos supported?</>,
    answer:   <>Yes. Just make sure your video is less than 1920 pixels tall or wide, and keep the duration under 60 seconds.</>,
  })

  if (typeof maxSocialAccounts === 'number') {
    faq.push({
      question: <>How many social accounts can be added?</>,
      answer:   <>You can add up to {maxSocialAccounts} social accounts. If you need more, <a className="link" onClick={() => sendMessage('help', {message: `I would like to add more than ${maxSocialAccounts} social accounts.`})}>let us know</a>.</>,
    })
  }

  if (!mobile) {
    faq.push({
      question: <>Can there be multiple posts on a day? </>,
      answer:   <>Yes. If a date already has a post, you can tap in the space just above the post, to add another.</>,
    })
  }

  faq.push({
    question: <>Can old posts be reused?</>,
    // answer:   <>Currently, there is no direct feature for reusing posts, but you can copy the caption and image.<br/><br/>Just open an old post and tap the download button <DownloadIcon/></>,
    answer:   <>Open the old post and tap the download button <DownloadIcon/> to copy the caption and the image.</>,
  })

  if (currentOrg?.showBilling) {
    faq.push({
      question: <>Is this a monthly subscription?</>,
      answer:   <>You can choose monthly or annual.<br/><br/>The annual option has a 15% discount, but no refunds if you cancel early.</>,
    })

    faq.push({
      question: <>Are there any cancellation fees?</>,
      answer:   <>Nope. We don't have any cancellation fees. You pay upfront. So, you can cancel anytime, and your account will stay active until the end of your billing cycle.</>,
    })
  }

  if (canCreateExtraAccounts) {
    faq.push({
      question: <>Can we create separate accounts for different companies (or branches)?</>,
      answer:   <>Absolutely!<br/><br/><a className="link" onClick={() => sendMessage('accounts')}>Open the accounts menu</a>, by tapping on your account name (in the top-right corner of the app). Then tap <b>Create a New Account</b>.</>,
    })
  } else {
    faq.push({
      question: <>Can we create separate accounts for different companies (or branches)?</>,
      answer:   <>Absolutely!<br/><br/>Just <a className="link" onClick={() => sendMessage('help', {message: 'I would like to add another account, so I can have separate calendars.'})}>ask for another account</a>, and we'll hook you up.</>,
    })
  }


  return <>
    <section className="settings p-4 pb-6 flex-col gap-6">
      <div>
        <h2 className="text-lg mb-2 semi-bold">
          How to...
        </h2>

        {howto.map((item, index) => (
          <div key={index} className={classNames("accordion text-sm", {active: activeHowTo === index})}>
            <label className="question" onClick={() => toggleHowTo(index)}>
              <span className="indicator">
                ►
              </span>
              <span className="text">
                {item.question}
              </span>
            </label>
            <div className={classNames("answer")}>
              {item.answer}
            </div>
          </div>
        ))}
      </div>

      <div>
        <h2 className="text-lg mb-2 semi-bold">
          Common Questions
        </h2>

        {faq.map((item, index) => (
          <div key={index} className={classNames("accordion text-sm", {active: activeFAQ === index})}>
            <label className="question" onClick={() => toggleFAQ(index)}>
              <span className="indicator">
                ►
              </span>
              <span className="text">
                {item.question}
              </span>
            </label>
            <div className="answer">
              {item.answer}
            </div>
          </div>
        ))}
      </div>

      <div className="flex-col gap-2">
        <h2 className="text-lg bold m-0">
          Need more help?
        </h2>

        <button className="button is-primary" onClick={() => sendMessage('help')}>
          Contact Support
        </button>
      </div>
    </section>


    <style jsx>{`
      .accordion {
        border-radius: 4px;
        border: 1px solid #ddd;
        margin-bottom: -1px;
        position: relative;
      }
      .accordion:hover,
      .accordion.active {
        background: none;
        background: #3499CC0F;
        border-color: #3499CC99;
        z-index: 1;
      }
      .accordion.active {
        background: #3499CC20;
        border-color: #3499CC;
      }
      .question {
        cursor: pointer;
        display: flex;
        padding: 0.5rem 1rem;
        color: #1a79a9;
      }
      .question .indicator {
        display: inline-block;
        display: none;
        margin-right: 8px;
        transition: transform 0.5s ease-in-out;
        transform-origin: 25% 25%;
      }
      .active .question .indicator {
        transform: rotate(90deg);
      }
      .answer {
        height: 0;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        padding: 0rem 1rem;
        box-sizing: border-box;
        opacity: 0;
      }
      .answer :global(svg) {
        vertical-align: bottom;
        height: 1.25rem;
      }
      .active .answer {
        height: 100px;
        padding: 0.5rem 1rem;
        opacity: 1;
        transition: all 0.5s ease-in-out;
      }
    `}</style>
  </>
}
