import { useState } from "react";


const FREQUENCY_OPTIONS = [
  { value: 'barely', min: 1, max: 1 },
  { value: 'low',    min: 2, max: 3 },
  { value: 'medium', min: 4, max: 5 },
  { value: 'high',   min: 6, max: 7 },
  { value: 'daily',  min: 7, max: 7 },
]


export default function SetupFrequency({redirectAfter, onSubmit, defaultFrequency}) {
  const inSignup = !!redirectAfter

  let defaultValue = inSignup ? 'medium' : ''
  if (defaultFrequency) {
    const found = FREQUENCY_OPTIONS.find(o => o.min === defaultFrequency.min && o.max === defaultFrequency.max)
    if (found) defaultValue = found.value
  }
  const [frequency, setFrequency] = useState(defaultValue)


  async function nextStep (e) {
    e.preventDefault()

    const wrapper = redirectAfter || function (callback) {callback()}

    wrapper(async () => {
      const frequencyConfig = FREQUENCY_OPTIONS.find(o => o.value === frequency)
      const min = frequencyConfig.min
      const max = frequencyConfig.max

      return await onSubmit({min, max})
    })
  }


  return <>
    <section className="content double-padded">
      <form onSubmit={nextStep}>

        <h1>How many posts do you want?</h1>

        <div className="options">

          { FREQUENCY_OPTIONS.map(({value, label, min, max}) => (
            <div className="control" key={value}>
              <label className="radio">
                <input
                  type="radio"
                  name="frequency"
                  checked={frequency === value}
                  onChange={e => { if (e.target.checked) setFrequency(value) }}
                  value={value}
                />{ min === max ?
                  ` ${min} post${min === 1 ? '' : 's'} per week` :
                  ` ${min} to ${max} per week`
                }
                {
                  value === 'medium' &&
                  <span className="text-sm gray"> (recommended)</span>
                }
              </label>
            </div>
          ))}

        </div>

        <div className="flex-row gap-2 flex-center">
          <button className="button is-primary" disabled={!frequency}>
            {inSignup ? 'Continue' : 'Save'}
          </button>
          { !inSignup &&
            <button className="button" onClick={() => onSubmit()}>
              Cancel
            </button>
          }
        </div>
      </form>
    </section>

    <style jsx>{`
      section {
        max-width: 440px;
        margin: auto;
        text-align: center;
      }
      @media (max-width: 350px) {
        section {
          padding: 2rem 1rem;
        }
      }
      .options {
        display: flex;
        flex-direction: column;
        width: 300px;
        text-align: left;
        margin: 2rem auto;
      }
      label {
        padding: 0.7rem;
        display: block;
        font-size: 1.2rem;
      }
      .other-option {
        font-style: italic;
        color: #666;
      }
      .control {
        margin-bottom: -1px;
        border: 1px solid #ccc;
        position: relative;
      }
      input {
        margin-right: 6px;
      }
      .other-org-type {
        margin: 0rem 2rem 1rem;
        max-width: 80%;
      }
    `}</style>
  </>
}
