import classNames from "classnames";
import LoadingSpinner from "./LoadingSpinner";
import ImageEditor from "./ImageEditor";
import { useState } from "react";
import useRpc from "@/src/useRpc";
import Modal from "./Modal";

export default function ImageTemplateSettings ({org}) {
  const [imageTemplates, _err, reload] = useRpc('imageTemplates.all', {orgId: org.id})

  const [activeImageTemplate, setActiveImageTemplate] = useState(null)

  if (!imageTemplates) return <LoadingSpinner />

  return <>
    <section className="settings p-4 pb-6 flex-col gap-6 bg-f8f8f8">
      <div className="flex-col gap-4">
        { imageTemplates.map(template => (
          <div key={template.id} className={classNames('image-template shadow-lg flex-col relative', template.status)}>
            <a className='flex' onClick={() => setActiveImageTemplate(template)}>
              <img src={template.image.thumbnailUrl} alt={template.description}/>
            </a>
          </div>
        ))}
      </div>

    </section>

    { activeImageTemplate &&
      <Modal visible>
        <div className="padded flex-col gap-4" style={{maxHeight: '95vh', display: 'flex'}}>

          <ImageEditor
            mode="template"
            image={activeImageTemplate.image}
            onSave={() => {
              reload()
              setActiveImageTemplate(null)
            }}
            onCancel={() => setActiveImageTemplate(null)}
          />

        </div>
      </Modal>
    }

</>
}
